<div class="container-fluid">
  <div class="card general-widget">
    <div class="card-header no-border padding-15">
      <ul class="creative-dots">
        <li class="bg-primary big-dot"></li>
        <li class="bg-secondary semi-big-dot"></li>
        <li class="bg-warning medium-dot"></li>
        <li class="bg-info semi-medium-dot"></li>
        <li class="bg-secondary semi-small-dot"></li>
        <li class="bg-primary small-dot"></li>
      </ul>
      <div class="row">
        <div class="col-6">
          <h4 class="m-l-50 m-t-10">
            Referred By - Home
          </h4>
        </div>
        <div class="col-6">

        </div>
      </div>
    </div>

    <div class="card-body m-t-20 padding-15">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 xl-100 box-col-12">
          <div class="card custom-ui-card">
            <div class="card-header p-20 no-border">
              <h5 class="m-l-50"> Referred By Report</h5>
              <ul class="creative-dots">
                <li class="bg-primary big-dot"></li>
                <li class="bg-secondary semi-big-dot"></li>
                <li class="bg-warning medium-dot"></li>
                <li class="bg-info semi-medium-dot"></li>
                <li class="bg-secondary semi-small-dot"></li>
                <li class="bg-primary small-dot"></li>
              </ul>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 box-col-6 xl-50 col-xl-4">
                  <div class="card o-hidden sml-card">
                    <div class="b-r-4 card-body">
                      <div class="media static-top-widget">
                        <div class="align-self-center text-center color-teal p-2 square  px-3" style="background-color: #e2f8ed;">
                          <app-feather-icons [icon]="'users'"></app-feather-icons>
                        </div>

                        <div class="media-body"><span class="m-0 ">Total Students</span>
                          <h4 class="mb-0 counter">{{referredCount | IndianNumber}}</h4>
                          <i class="icon-bg" data-feather="users"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

