import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {ReferredByService} from "../../shared/services/referred-by.service";

@Component({
  selector: 'ps-referred-by',
  templateUrl: './referred-by.component.html',
  styleUrls: ['./referred-by.component.scss']
})
export class ReferredByComponent implements OnInit {
  referredCount: any = 0;
  constructor(private router: Router,
              private referredByService: ReferredByService,) { }

  ngOnInit(): void {
    this.refresh();
  }
  refresh() {
    this.getCollegeCount();
  }
  getCollegeCount() {
    this.referredByService.totalCount().subscribe(data =>{
      this.referredCount = data;
    })
  }
  goTo(url) {
    this.router.navigateByUrl(url);
  }

}
