import { Component, OnInit } from '@angular/core';
import {NgbModal, NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from "@angular/router";
import {NotifyService} from "../../../shared/services/notify.service";

@Component({
  selector: 'ps-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.scss']
})
export class AddNotificationComponent implements OnInit {
  searchForm:FormGroup;
  notifications: any = [];
  addNotificationForm: FormGroup;
  FollowUps = [
    {
      name: 'Notification dated 10.08.2022 regarding addition for Second Phase date-Sheet for B.Sc. (Honours) Polymer Science & Food Technology Choice Based Credit System (CBCS-LOCF) Semester Examinations August-2022 meant for Part-3 (VI Semester) & Part-2 (IV Semester) students alongwith this only Ex-students, Essential Repeaters and Improvement cases of Part-1 (II Semester) shall also appear as per rule (Admission in 2019 & 2020) ',
      created_at: '15/08/2022 01:39:24',
      status: 'Active'
    },
    {
      name: 'Notification dated 10.08.2022 regarding Filling of the examination forms for Compulsory Test in Hindi (CTH) for Choice Based Credit System (CBCS) for Under Graduate Semester Examinations August-2022',
      created_at: '14/08/2022 13:39:12',
      status: 'In Active'
    },
    {
      name: 'Regarding GE Paper in Indian Economy I Exam notification: Notification dated 05.08.2022 regarding additon of GE Paper in Indian Economy I Exams August-2022 ',
      created_at: '15/08/2022 02:39:42',
      status: 'In Active'
    },
  ];

  pagination = {
    page: 0,
    total: null,
    perpage: 50,
    pages: null
  };
  page = 4;
  submitted: any = false;
  advancePage = 1;
  currentPage = 3;
  isDisabled = true;
  closeResult: string;
  selectedData: any;
  modalType: any;
  constructor(config: NgbPaginationConfig,
              private modalService: NgbModal,

              private fb: FormBuilder,
              private router: Router,
              private notificationService: NotifyService) {
    config.size = 'sm';
    config.boundaryLinks = true;
  }

  ngOnInit() {
    this.refresh();
  }


  refresh() {
    this.addNotificationForm = this.fb.group({
      notification: ['', Validators.required ]
    });


    this.get();

  }




  submit() {
    // this.modalType = 'ADD';
    this.submitted = true;
    if (!this.addNotificationForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'notification': (this.addNotificationForm && this.addNotificationForm.getRawValue() && this.addNotificationForm.getRawValue().notification) ? this.addNotificationForm.getRawValue().notification : '',
      };
      this.notificationService.create(params).subscribe(data => {
        this.submitted = false;
        this.modalService.dismissAll();
        this.addNotificationForm.patchValue({
          'notification': '',

        });
      }, error => {
        this.submitted = false;
      });
    }

  }

  update() {
    // this.modalType = 'UPDATE';
    this.submitted = true;
    if (!this.addNotificationForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'notification': (this.addNotificationForm && this.addNotificationForm.getRawValue() && this.addNotificationForm.getRawValue().notification) ? this.addNotificationForm.getRawValue().notification : '',
      };
      this.notificationService.update(this.selectedData.id, params).subscribe(data => {
        this.submitted = false;
        this.modalService.dismissAll('');
        this.resetForm();
        this.selectedData = undefined;
        this.get();
      }, error => {
        this.submitted = false;
      });
    }
  }
  changeStatus(data, status) {
    this.submitted = true;
    if (this.submitted) {
      this.notificationService.getStatus(data.id, {'status': status}).subscribe(data => {
        this.submitted = false;
        this.get();
      }, error => {
        this.submitted = false;
      });
    }
  }

  get(params: any = {}){
    this.notifications = [];
    this.notificationService.get(params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.notifications = data['data'];
      }
      this.pagination = {
        page: data.current_page,
        total: data.total,
        perpage: data.per_page,
        pages: data.last_page,
      };
    });
  }

  openLg(content) {
    this.modalService.open(content, {size: 'lg'});
  }

  toggleDisabled() {
    this.isDisabled = !this.isDisabled;
  }

  onPageChange(page) {
    this.pagination.page = page;
    const params = {
      'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
      'username': (this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
      'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
      'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : '',
      'state_id': (this.searchForm.value && this.searchForm.value.state_id) ? this.searchForm.value.state_id : ''
    };
    /*this.get(params);*/
  }
  openEditModal (content, data) {
    this.resetForm();
    this.modalService.open(content, {size: 'md'});
    this.selectedData = data;
    this.addNotificationForm.patchValue({
      'notification': data.notification,
      'id': this.selectedData.id,
    });
  }
  resetForm() {
    this.addNotificationForm.patchValue({
      'notification': '',
    });
    this.selectedData = undefined;
  }
}
