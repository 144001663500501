import {HostListener, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UniversityService} from './university.service';
import {StorageService} from './storage.service';
import {PermissionConstant} from '../constants/permission-constant';
import {Helper} from '../constants/helper';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  availableTo?: string[];
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})

export class NavService {
  user: any;
  userData: any;

  constructor(private universityService: UniversityService) {
    this.onResize();
    this.user = StorageService.getItem('self');
    this.getUserDetail();
    if (this.screenWidth < 991) {
      this.collapseSidebarMainMenu = false;
      this.collapseSidebarSecondMenu = true;
    }
  }

  public openclass: boolean = false;
  public screenWidth: any;

  public collapseSidebarMainMenu = false;
  public collapseSidebarSecondMenu = false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth >= 991;

  public fullScreen = false;

  MENUITEMS: Menu[] = [
    {
      title: 'General',
      path: '/general/home',
      icon: 'pe-7s-home',
      type: 'sub',
      active: true,
      availableTo: [PermissionConstant.ALL],
      children:
        [
          {
            title: 'General', type: 'sub', active: true, children: [
              {
                path: '/general/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.ALL]
              }
            ]
          }
        ],
    },
    {
      title: 'Sessions',
      path: '/sessions/home',
      icon: 'fas fa-sliders-h',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.ADD_SESSION, PermissionConstant.LIST_SESSION],
      children:
        [
          {
            title: 'Sessions', type: 'sub', active: true, children: [
              {
                path: '/sessions/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.ADD_SESSION, PermissionConstant.LIST_SESSION],
              },
              {
                path: '/sessions/add-session',
                title: 'Add Session',
                type: 'link',
                availableTo: [PermissionConstant.ADD_SESSION]
              },
              {
                path: '/sessions/list-session',
                title: 'List Session',
                type: 'link',
                availableTo: [PermissionConstant.LIST_SESSION]
              },
              {
                path: '/sessions/re-register-session',
                title: 'Re-Register Session',
                type: 'link',
                availableTo: [PermissionConstant.ADD_SESSION]
              },
              {
                path: '/sessions/list-re-registered-session',
                title: 'List Re-Registered Session',
                type: 'link',
                availableTo: [PermissionConstant.LIST_SESSION]
              },
            ]
          }
        ],
    },
    {
      title: 'Modules',
      path: '/modules/home',
      icon: 'fas fa-bookmark',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.PROGRAMS, PermissionConstant.COURSES, PermissionConstant.BRANCHES, PermissionConstant.MAPPINGS, PermissionConstant.SUBJECTS],
      children:
        [
          {
            title: 'Modules', type: 'sub', active: true, children: [
              {
                path: '/modules/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.PROGRAMS, PermissionConstant.COURSES, PermissionConstant.BRANCHES, PermissionConstant.MAPPINGS, PermissionConstant.SUBJECTS],
              },
              {
                path: '/modules/programs',
                title: 'Programs',
                type: 'link',
                availableTo: [PermissionConstant.PROGRAMS],
              },
              {
                path: '/modules/courses',
                title: 'Courses',
                type: 'link',
                availableTo: [PermissionConstant.COURSES],
              },
              {
                path: '/modules/branches',
                title: 'Branches',
                type: 'link',
                availableTo: [PermissionConstant.BRANCHES],
              },
              {
                path: '/modules/mappings',
                title: 'Mappings',
                type: 'link',
                availableTo: [PermissionConstant.MAPPINGS],
              },
              {
                path: '/modules/subjects',
                title: 'Subjects',
                type: 'link',
                availableTo: [PermissionConstant.SUBJECTS],
              }
            ]
          }
        ],
    },
    {
      title: 'Referred By',
      path: '/referred-by/home',
      icon: 'pe-7s-helm',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.ADD_REFERRED_BY, PermissionConstant.LIST_REFERRED_BY],
      children:
        [
          {
            title: 'Referred By', type: 'sub', active: true, children: [
              {
                path: '/referred-by/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.ADD_REFERRED_BY, PermissionConstant.LIST_REFERRED_BY],
              },
              {
                path: '/referred-by/add-referred-by',
                title: 'Add Referred By',
                type: 'link',
                availableTo: [PermissionConstant.ADD_REFERRED_BY]
              },
              {
                path: '/referred-by/list-referred-by',
                title: 'List Referred By',
                type: 'link',
                availableTo: [PermissionConstant.LIST_REFERRED_BY]
              }
            ]
          }
        ],
    },
 /*   {
      title: 'Centers',
      path: '/centers/home',
      icon: 'pe-7s-culture',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.ADD_CENTER, PermissionConstant.LIST_CENTER, PermissionConstant.SHARE_PERCENTAGE, PermissionConstant.ADMISSION_REPORT, PermissionConstant.IA_AW_MARKS],
      children:
        [
          {
            title: 'Centers', type: 'sub', active: true, children: [
              {
                path: '/centers/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.ADD_CENTER, PermissionConstant.LIST_CENTER, PermissionConstant.SHARE_PERCENTAGE, PermissionConstant.ADMISSION_REPORT, PermissionConstant.IA_AW_MARKS],
              },
              {
                path: '/centers/add',
                title: 'Add Center',
                type: 'link',
                availableTo: [PermissionConstant.ADD_CENTER],
              },
              {
                path: '/centers/list',
                title: 'List Centers',
                type: 'link',
                availableTo: [PermissionConstant.LIST_CENTER],
              },
              {
                path: '/centers/share-percentage',
                title: 'Share Percentage',
                type: 'link',
                availableTo: [PermissionConstant.SHARE_PERCENTAGE],
              },
              {
                path: '/centers/admission-report',
                title: 'Admission Report',
                type: 'link',
                availableTo: [PermissionConstant.ADMISSION_REPORT],
              },
              {
                path: '/centers/ia-aw-marks',
                title: 'IA - AW Marks',
                type: 'link',
                availableTo: [PermissionConstant.IA_AW_MARKS],
              }
            ]
          }
        ],
    },*/
    {
      title: 'Students',
      path: '/students/home',
      icon: 'fas fa-user-graduate',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.ADD_STUDENTS, PermissionConstant.DIRECT_STUDENTS, PermissionConstant.CENTER_STUDENTS, PermissionConstant.VERIFY_DIRECT_STUDENTS, PermissionConstant.VERIFY_CENTER_STUDENTS, PermissionConstant.ASSIGN_ENROLLMENT, PermissionConstant.FINAL_VERIFICATION, PermissionConstant.ASSIGN_ROLL_NO, PermissionConstant.ALL_STUDENTS],
      children:
        [
          {
            title: 'Student', type: 'sub', active: true, children: [
              {
                path: '/students/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.DIRECT_STUDENTS, PermissionConstant.CENTER_STUDENTS, PermissionConstant.VERIFY_DIRECT_STUDENTS, PermissionConstant.VERIFY_CENTER_STUDENTS, PermissionConstant.ASSIGN_ENROLLMENT, PermissionConstant.FINAL_VERIFICATION, PermissionConstant.ASSIGN_ROLL_NO, PermissionConstant.ALL_STUDENTS]
              },
              /*{path: '/students/follow-up-stages', title: 'Follow Up Stages', type: 'link'},
              {path: '/students/student-enquiries', title: 'Student Enquiries', type: 'link'},*/
              {
                path: '/students/add-student',
                title: 'Add Student',
                type: 'link',
                availableTo: [PermissionConstant.ADD_STUDENTS]
              },
              {
                path: '/students/direct-students',
                title: 'List Students',
                type: 'link',
                availableTo: [PermissionConstant.DIRECT_STUDENTS]
              },
             /* {
                path: '/students/verify-direct-students',
                title: 'Verify Direct Students',
                type: 'link',
                availableTo: [PermissionConstant.VERIFY_DIRECT_STUDENTS]
              },
              {
                path: '/students/assign-enrollment',
                title: 'Assign Enrollment',
                type: 'link',
                availableTo: [PermissionConstant.ASSIGN_ENROLLMENT]
              },
              {
                path: '/students/final-verification',
                title: 'Final Verification',
                type: 'link',
                availableTo: [PermissionConstant.FINAL_VERIFICATION]
              },
              {
                path: '/students/student-evaluation',
                title: 'Student Evaluation',
                type: 'link',
                availableTo: [PermissionConstant.FINAL_VERIFICATION]
              },
              {
                path: '/students/assign-roll-no',
                title: 'Assign Roll No.',
                type: 'link',
                availableTo: [PermissionConstant.ASSIGN_ROLL_NO]
              },
              {
                path: '/students/re-registered-students',
                title: 'Re - Registered Students',
                type: 'link',
                availableTo: [PermissionConstant.RE_REGISTERED_STUDENTS]
              },
              {
                path: '/students/transfer-requests',
                title: 'Transfer Requests',
                type: 'link',
                availableTo: [PermissionConstant.TRANSFER_REQUESTS]
              },*/
              /*{
                path: '/students/all-students',
                title: 'All Students',
                type: 'link',
                availableTo: [PermissionConstant.ALL_STUDENTS]
              }*/
            ]
          }
        ],
    },
    /*{
      title: 'LMS',
      path: '/lms/home',
      icon: 'pe-7s-monitor',
      type: 'sub',
      active: false,
      //availableTo: [PermissionConstant.CENTERS_ADD_MONEY, PermissionConstant.CENTERS_TRANSACTIONS, PermissionConstant.CENTERS_RECEIPTS],
      children:
        [
          {
            title: 'LMS', type: 'sub', active: true, children: [
              {
                path: '/lms/home',
                title: 'Home',
                type: 'link',
                //availableTo: [PermissionConstant.CENTERS_ADD_MONEY, PermissionConstant.CENTERS_TRANSACTIONS, PermissionConstant.CENTERS_RECEIPTS]
              },
              {
                path: '/lms/live-lecture',
                title: 'Live Lecture',
                type: 'link',
                //availableTo: [PermissionConstant.CENTERS_ADD_MONEY]
              },
              {
                path: '/lms/assignment-evaluation',
                title: 'Assignment Evaluation',
                type: 'link',
                //availableTo: [PermissionConstant.CENTERS_ADD_MONEY]
              }
            ]
          }
        ],
    },*/
    {
      title: 'Accounts',
      path: '/accounts/home',
      icon: 'fas fa-credit-card',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.OTHER_RECEIPTS, PermissionConstant.STUDENT_TRANSACTIONS],
      children:
        [
          {
            title: 'Accounts', type: 'sub', active: true, children: [
              {
                path: '/accounts/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.OTHER_RECEIPTS, PermissionConstant.STUDENT_TRANSACTIONS]
              },
              /*{path: '/accounts/students', title: 'Students', type: 'link'},
              {path: '/accounts/center-admissions', title: 'Center Admissions', type: 'link'},
              {path: '/accounts/center-re-registrations', title: 'Center Re-Registrations', type: 'link'},
              {path: '/accounts/direct-admissions', title: 'Direct Admissions', type: 'link'},
              {path: '/accounts/direct-re-registrations', title: 'Direct Re-Registrations', type: 'link'},
              {path: '/accounts/examinations', title: 'Examinations', type: 'link'},
              {path: '/accounts/revalutions', title: 'Revaluations', type: 'link'},
              {path: '/accounts/marksheets', title: 'Marksheets', type: 'link'},
              {path: '/accounts/original-degree', title: 'Original Degree', type: 'link'},
              {path: '/accounts/migrations', title: 'Migrations', type: 'link'},
              {path: '/accounts/transcripts', title: 'Transcripts', type: 'link'},*/
              {
                path: '/accounts/centers',
                title: 'Other Transactions ',
                type: 'link',
                availableTo: [PermissionConstant.OTHER_RECEIPTS]
              },
             {
                path: '/accounts/centers-transactions',
                title: 'Student Transactions',
                type: 'link',
               availableTo: [PermissionConstant.STUDENT_TRANSACTIONS]
              },
              /*  {
                 path: '/accounts/centers-receipts',
                 title: 'Receipts',
                 type: 'link',
                 availableTo: [PermissionConstant.CENTERS_RECEIPTS]
               },
               {
                 path: '/accounts/centers-report',
                 title: 'Center\'s - Report',
                 type: 'link',
                 availableTo: [PermissionConstant.CENTERS_REPORT]
               },
               {
                 path: '/accounts/centers-branches-report',
                 title: 'Center\'s Branch - Report',
                 type: 'link',
                 availableTo: [PermissionConstant.CENTERS_BRANCH_REPORT]
               },*/
            ]
          }
        ],
    },
    {
      title: 'Support',
      path: '/support/home',
      icon: 'pe-7s-headphones',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.ADMISSION_TICKETS, PermissionConstant.RE_REGISTRATION_TICKETS, PermissionConstant.EXAMINATION_TICKETS, PermissionConstant.MARKSHEET_TICKETS, PermissionConstant.TRANSCRIPT_TICKETS, PermissionConstant.REVALUATION_TICKETS, PermissionConstant.MIGRATION_TICKETS, PermissionConstant.ORIGINAL_DEGREE_TICKETS, PermissionConstant.E_LEARNING_TICKETS, PermissionConstant.OTHER_TICKETS],
      children:
        [
          {
            title: 'Support', type: 'sub', active: true, children: [
              {
                path: '/support/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.ADMISSION_TICKETS, PermissionConstant.RE_REGISTRATION_TICKETS, PermissionConstant.EXAMINATION_TICKETS, PermissionConstant.MARKSHEET_TICKETS, PermissionConstant.TRANSCRIPT_TICKETS, PermissionConstant.REVALUATION_TICKETS, PermissionConstant.MIGRATION_TICKETS, PermissionConstant.ORIGINAL_DEGREE_TICKETS, PermissionConstant.E_LEARNING_TICKETS, PermissionConstant.OTHER_TICKETS]
              },
              {
                path: '/support/admission-tickets',
                title: 'Admission Tickets',
                type: 'link',
                availableTo: [PermissionConstant.ADMISSION_TICKETS]
              },
              {
                path: '/support/re-registration-tickets',
                title: 'Re-Registration Tickets',
                type: 'link',
                availableTo: [PermissionConstant.RE_REGISTRATION_TICKETS]
              },
              {
                path: '/support/examination-tickets',
                title: 'Examination Tickets',
                type: 'link',
                availableTo: [PermissionConstant.EXAMINATION_TICKETS]
              },
              {
                path: '/support/marksheet-tickets',
                title: 'Marksheet Tickets',
                type: 'link',
                availableTo: [PermissionConstant.MARKSHEET_TICKETS]
              },
              {
                path: '/support/transcript-tickets',
                title: 'Transcript Tickets',
                type: 'link',
                availableTo: [PermissionConstant.TRANSCRIPT_TICKETS]
              },
              {
                path: '/support/revaluation-tickets',
                title: 'Revalution Tickets',
                type: 'link',
                availableTo: [PermissionConstant.REVALUATION_TICKETS]
              },
              {
                path: '/support/migration-tickets',
                title: 'Migration Tickets',
                type: 'link',
                availableTo: [PermissionConstant.MIGRATION_TICKETS]
              },
              {
                path: '/support/original-degree-tickets',
                title: 'Original Degree Tickets',
                type: 'link',
                availableTo: [PermissionConstant.ORIGINAL_DEGREE_TICKETS]
              },
              {
                path: '/support/e-learning-tickets',
                title: 'E-Learning Tickets',
                type: 'link',
                availableTo: [PermissionConstant.E_LEARNING_TICKETS]
              },
              {
                path: '/support/other-tickets',
                title: 'Other Tickets',
                type: 'link',
                availableTo: [PermissionConstant.OTHER_TICKETS]
              }
            ]
          }
        ],
    },
/*    {
      title: 'Issues',
      path: '/issues/home',
      icon: 'pe-7s-helm',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.MARKSHEET_ISSUES, PermissionConstant.TRANSCRIPT_ISSUES, PermissionConstant.REVALUATION_ISSUES, PermissionConstant.MIGRATION_ISSUES, PermissionConstant.ORIGINAL_DEGREE_ISSUES, PermissionConstant.PROVISIONAL_ISSUES, PermissionConstant.OTHER_ISSUES],
      children:
        [
          {
            title: 'Issues', type: 'sub', active: true, children: [
              {
                path: '/issues/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.MARKSHEET_ISSUES, PermissionConstant.TRANSCRIPT_ISSUES, PermissionConstant.REVALUATION_ISSUES, PermissionConstant.MIGRATION_ISSUES, PermissionConstant.ORIGINAL_DEGREE_ISSUES, PermissionConstant.PROVISIONAL_ISSUES, PermissionConstant.OTHER_ISSUES]
              },
              {
                path: '/issues/provisional-issues',
                title: 'Provisional Issues',
                type: 'link',
                availableTo: [PermissionConstant.PROVISIONAL_ISSUES]
              },
              {
                path: '/issues/marksheet-issues',
                title: 'Marksheet Issues',
                type: 'link',
                availableTo: [PermissionConstant.MARKSHEET_ISSUES]
              },
              {
                path: '/issues/transcript-issues',
                title: 'Transcript Issues',
                type: 'link',
                availableTo: [PermissionConstant.TRANSCRIPT_ISSUES]
              },
              {
                path: '/issues/revaluation-issues',
                title: 'Revalution Issues',
                type: 'link',
                availableTo: [PermissionConstant.REVALUATION_ISSUES]
              },
              {
                path: '/issues/migration-issues',
                title: 'Migration Issues',
                type: 'link',
                availableTo: [PermissionConstant.MIGRATION_ISSUES]
              },
              {
                path: '/issues/original-degree-issues',
                title: 'Original Degree Issues',
                type: 'link',
                availableTo: [PermissionConstant.ORIGINAL_DEGREE_ISSUES]
              },
              {
                path: '/issues/other-issues',
                title: 'Other Issues',
                type: 'link',
                availableTo: [PermissionConstant.OTHER_ISSUES]
              }
            ]
          }
        ],
    },*/
    {
      title: 'Dept.',
      path: '/departments/home',
      icon: 'fas fa-chalkboard',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.ADD_DEPARTMENT, PermissionConstant.LIST_DEPARTMENT, PermissionConstant.ADD_EMPLOYEE, PermissionConstant.LIST_EMPLOYEE, PermissionConstant.ADD_VIDEO_LECTURE, PermissionConstant.LIST_VIDEO_LECTURE, PermissionConstant.ADD_SLM, PermissionConstant.LIST_SLM],
      children:
        [
          {
            title: 'Departments', type: 'sub', active: true, children: [
              {
                path: '/departments/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.ADD_DEPARTMENT, PermissionConstant.LIST_DEPARTMENT, PermissionConstant.ADD_EMPLOYEE, PermissionConstant.LIST_EMPLOYEE, PermissionConstant.ADD_VIDEO_LECTURE, PermissionConstant.LIST_VIDEO_LECTURE, PermissionConstant.ADD_SLM, PermissionConstant.LIST_SLM]
              },
              {
                path: '/departments/add-department',
                title: 'Add Department',
                type: 'link',
                availableTo: [PermissionConstant.ADD_DEPARTMENT]
              },
              {
                path: '/departments/list-department',
                title: 'List Department',
                type: 'link',
                availableTo: [PermissionConstant.LIST_DEPARTMENT]
              },
              /*{path: '/departments/add-hod', title: 'Add HOD', type: 'link'},
              {path: '/departments/list-hod', title: 'List HODs', type: 'link'},*/
              {
                path: '/departments/add-employee',
                title: 'Add Employee',
                type: 'link',
                availableTo: [PermissionConstant.ADD_EMPLOYEE]
              },
              {
                path: '/departments/list-employee',
                title: 'List Employee',
                type: 'link',
                availableTo: [PermissionConstant.LIST_EMPLOYEE]
              }
            ]
          }
        ],
    },
   /* {
      title: 'Exams',
      path: '/exams/home',
      icon: 'pe-7s-note2',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.EXAM_SESSION, PermissionConstant.EXAMS_SCHEDULE_EXAM],
      children:
        [
          {
            title: 'Exams', type: 'sub', active: true, children: [
              {
                path: '/exams/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.EXAM_SESSION, PermissionConstant.EXAMS_SCHEDULE_EXAM]
              },
              {
                path: '/exams/exam-sessions',
                title: 'Exam Sessions',
                type: 'link',
                availableTo: [PermissionConstant.EXAM_SESSION]
              },
              {
                path: '/exams/schedule-exam',
                title: 'Schedule Exam',
                type: 'link',
                availableTo: [PermissionConstant.EXAMS_SCHEDULE_EXAM]
              },
              {
                path: '/exams/marked-back-exams',
                title: 'Marked Back Exams',
                type: 'link',
                availableTo: [PermissionConstant.EXAMS_MARKED_BACK_EXAMS]
              },
              /!*{path: '/exams/admit-card', title: 'Admit Card', type: 'link'},
              *!/
              /!*{
                path: '/exams/appearing-candidates',
                title: 'Appearing Candidates',
                type: 'link'
              },*!/
              /!*{path: '/exams/add-question-paper', title: 'Add Question Paper', type: 'link'},
              {path: '/exams/list-question-paper', title: 'List Question Papers', type: 'link'},
              *!/
              /!*{
                path: '/exams/student-exam',
                title: 'Student Exam',
                type: 'link'
              },*!/
              {path: '/exams/student-examination', type: 'link'},
              {path: '/exams/add-section', type: 'link'},
              {path: '/exams/add-question', type: 'link'},
            ]
          }
        ],
    },*/
    {
      title: 'Access',
      path: '/access/home',
      icon: 'fas fa-globe',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.ROLES, PermissionConstant.PERMISSIONS],
      children:
        [
          {
            title: 'Accessibility', type: 'sub', active: true, children: [
              {
                path: '/access/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.ROLES, PermissionConstant.PERMISSIONS]
              },
              {
                path: '/access/roles',
                title: 'Roles',
                type: 'link',
                availableTo: [PermissionConstant.ROLES]
              },
              {
                path: '/access/permissions',
                title: 'Permissions',
                type: 'link',
                availableTo: [PermissionConstant.PERMISSIONS]
              },
            ]
          }
        ],
    },
/*    {
      title: 'Results', path: '/results/home', icon: 'pe-7s-study', type: 'sub', active: false, children:
        [
          {
            title: 'Results', type: 'sub', active: true, children: [
              {path: '/results/home', title: 'Home', type: 'link'},
              {path: '/results/schedule-result', title: 'Schedule Result', type: 'link'},
              {path: '/results/student-result', title: 'Student Result', type: 'link'},
              {path: '/results/revaluation', title: 'Revaluation', type: 'link'},
            ]
          }
        ],
    },*/
    {
      title: 'Docs',
      path: '/docs/home',
      icon: 'pe-7s-study',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.DOCS_MARKSHEET, PermissionConstant.DOCS_MARKSHEET_VERIFY_DOC, PermissionConstant.DOCS_MARKSHEET_VERIFY_AMOUNT, PermissionConstant.DOCS_MARKSHEET_UPDATE_PRINT_STATUS, PermissionConstant.DOCS_MARKSHEET_UPDATE_CONSIGNMENT_NO, PermissionConstant.DOCS_MARKSHEET_UPDATE_DELIVERY_DATE, PermissionConstant.DOCS_CMM, PermissionConstant.DOCS_CMM_VERIFY_DOC, PermissionConstant.DOCS_CMM_VERIFY_AMOUNT, PermissionConstant.DOCS_CMM_UPDATE_PRINT_STATUS, PermissionConstant.DOCS_CMM_UPDATE_CONSIGNMENT_NO, PermissionConstant.DOCS_CMM_UPDATE_DELIVERY_DATE, PermissionConstant.DOCS_ORIGINAL_DEGREE, PermissionConstant.DOCS_ORIGINAL_DEGREE_VERIFY_DOC, PermissionConstant.DOCS_ORIGINAL_DEGREE_VERIFY_AMOUNT, PermissionConstant.DOCS_ORIGINAL_DEGREE_UPDATE_PRINT_STATUS, PermissionConstant.DOCS_ORIGINAL_DEGREE_UPDATE_CONSIGNMENT_NO, PermissionConstant.DOCS_ORIGINAL_DEGREE_UPDATE_DELIVERY_DATE, PermissionConstant.DOCS_MARKSHEET_VIEW_AMOUNT, PermissionConstant.DOCS_CMM_VIEW_AMOUNT, PermissionConstant.DOCS_ORIGINAL_DEGREE_VIEW_AMOUNT, PermissionConstant.DOCS_MARKSHEET_PRINT_LABEL, PermissionConstant.DOCS_CMM_PRINT_LABEL, PermissionConstant.DOCS_ORIGINAL_DEGREE_PRINT_LABEL],
      children:
        [
          {
            title: 'Docs', type: 'sub', active: true, children: [
              {
                path: '/docs/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.DOCS_MARKSHEET, PermissionConstant.DOCS_MARKSHEET_VERIFY_DOC, PermissionConstant.DOCS_MARKSHEET_VERIFY_AMOUNT, PermissionConstant.DOCS_MARKSHEET_UPDATE_PRINT_STATUS, PermissionConstant.DOCS_MARKSHEET_UPDATE_CONSIGNMENT_NO, PermissionConstant.DOCS_MARKSHEET_UPDATE_DELIVERY_DATE, PermissionConstant.DOCS_MARKSHEET_VIEW_AMOUNT, PermissionConstant.DOCS_CMM_VIEW_AMOUNT, PermissionConstant.DOCS_ORIGINAL_DEGREE_VIEW_AMOUNT, PermissionConstant.DOCS_MARKSHEET_PRINT_LABEL, PermissionConstant.DOCS_CMM_PRINT_LABEL, PermissionConstant.DOCS_ORIGINAL_DEGREE_PRINT_LABEL]
              },
              {
                path: '/docs/marksheet',
                title: 'Marksheet',
                type: 'link',
                availableTo: [PermissionConstant.DOCS_MARKSHEET, PermissionConstant.DOCS_MARKSHEET_VERIFY_DOC, PermissionConstant.DOCS_MARKSHEET_VERIFY_AMOUNT, PermissionConstant.DOCS_MARKSHEET_UPDATE_PRINT_STATUS, PermissionConstant.DOCS_MARKSHEET_UPDATE_CONSIGNMENT_NO, PermissionConstant.DOCS_MARKSHEET_UPDATE_DELIVERY_DATE, PermissionConstant.DOCS_MARKSHEET_VIEW_AMOUNT, PermissionConstant.DOCS_MARKSHEET_PRINT_LABEL]
              },
              /*{
                path: '/docs/cmm',
                title: 'CMM',
                type: 'link',
                availableTo: [PermissionConstant.DOCS_CMM, PermissionConstant.DOCS_CMM_VERIFY_DOC, PermissionConstant.DOCS_CMM_VERIFY_AMOUNT, PermissionConstant.DOCS_CMM_UPDATE_PRINT_STATUS, PermissionConstant.DOCS_CMM_UPDATE_CONSIGNMENT_NO, PermissionConstant.DOCS_CMM_UPDATE_DELIVERY_DATE, PermissionConstant.DOCS_CMM_VIEW_AMOUNT, PermissionConstant.DOCS_CMM_PRINT_LABEL]
              },*/
              {
                path: '/docs/original-degree',
                title: 'Original Degree',
                type: 'link',
                availableTo: [PermissionConstant.DOCS_ORIGINAL_DEGREE, PermissionConstant.DOCS_ORIGINAL_DEGREE_VERIFY_DOC, PermissionConstant.DOCS_ORIGINAL_DEGREE_VERIFY_AMOUNT, PermissionConstant.DOCS_ORIGINAL_DEGREE_UPDATE_PRINT_STATUS, PermissionConstant.DOCS_ORIGINAL_DEGREE_UPDATE_CONSIGNMENT_NO, PermissionConstant.DOCS_ORIGINAL_DEGREE_UPDATE_DELIVERY_DATE, PermissionConstant.DOCS_ORIGINAL_DEGREE_VIEW_AMOUNT, PermissionConstant.DOCS_ORIGINAL_DEGREE_PRINT_LABEL]
              }
            ]
          }
        ],
    },
    {
      title: 'Notify', path: '/notify/home', icon: 'fas fa-bell', type: 'sub', active: false, children:
        [
          {
            title: 'Notify', type: 'sub', active: true, children: [
              {path: '/notify/home', title: 'Home', type: 'link'},
              {path: '/notify/add', title: 'Add Notification', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Enquiry', path: '/enquiry/home', icon: 'fas fa-question-circle', type: 'sub', active: false, children:
        [
          {
            title: 'Enquiry', type: 'sub', active: true, children: [
              {path: '/enquiry/home', title: 'Home', type: 'link'},
              {path: '/enquiry/add', title: 'Add Enquiry', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Calendar', path: '/calender/home', icon: 'fa fa-calendar', type: 'sub', active: false, children:
        [
          {
            title: 'Calendar', type: 'sub', active: true, children: [
              {path: '/calender/home', title: 'Home', type: 'link'},
              {path: '/calender/add-holiday', title: 'Add Holiday', type: 'link'},
            ]
          }
        ],
    }
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

  getUserDetail() {
    this.universityService.detail(this.user.user_id).subscribe(data => {
      if (data) {
        this.userData = data;
        if (this.userData && this.userData['id'] !== 1) {
          let rolePermissions = [];
          if (this.userData && this.userData['role'] && this.userData['role']['role_permissions'] && this.userData['role']['role_permissions'].length > 0 && this.userData['role']['status'] && this.userData['role']['status'] === 'ACTIVE') {
            this.userData['role']['role_permissions'].forEach(subPermission => {
              if (subPermission && subPermission['sub_permission'] && subPermission['sub_permission']['meta']) {
                rolePermissions.push(subPermission['sub_permission']['meta']);
              }
            });
          }
          /*this.MENUITEMS.forEach(menu => {
            menu['is_hidden'] = menu && menu.title !== 'Students' && this.userData['user_type'] && this.userData['user_type'] === 'ACADEMIC_STAFF';
            if (menu['children'] && menu['children'].length > 0 && menu['children'][0]) {
              if (menu['children'][0] && menu['children'][0]['children'] && menu['children'][0] && menu['children'][0]['children'].length > 0) {
                menu['children'][0]['children'].forEach(child => {
                  child['is_hidden'] = child && child.title !== 'Final Verification' && this.userData['user_type'] && this.userData['user_type'] === 'ACADEMIC_STAFF';
                });
              }
            }
          });*/
          this.MENUITEMS.forEach(menu => {
            if (menu && menu.availableTo) {
              if (!menu['availableTo'].includes('ALL')) {
                let checkMenuPermission = Helper.findCommonElements(menu.availableTo, rolePermissions);
                menu['is_hidden'] = !checkMenuPermission;
                if (menu['children'] && menu['children'].length > 0 && menu['children'][0]) {
                  if (menu['children'][0] && menu['children'][0]['children'] && menu['children'][0] && menu['children'][0]['children'].length > 0) {
                    menu['children'][0]['children'].forEach(child => {
                      let checkSubMenuPermission = Helper.findCommonElements(child.availableTo, rolePermissions);
                      child['is_hidden'] = !checkSubMenuPermission;
                    });
                  }
                }
              } else {
                menu['is_hidden'] = false;
              }
            } else {
              menu['is_hidden'] = true;
            }
          });
        }
      }
    });
  }

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }
}
